import type { FC } from 'react';

import TextWithLineBreaks from '@hyex/modules/shared/Inputs/Text';
import clsx from 'clsx';

export type PropTypes = {
  description: string;
  shortDescription: string;
  shortInPriority?: boolean;
  symbolsForDescription?: number;
  className?: string;
};

const ProductDescription: FC<PropTypes> = ({
  description,
  shortInPriority = true,
  shortDescription,
  symbolsForDescription = 80,
  className,
}) => {
  const addDots = description?.length > symbolsForDescription;

  const descriptionText = description?.slice(0, symbolsForDescription);

  const text = shortInPriority ? shortDescription ?? descriptionText : descriptionText;

  return (
    <p className={clsx('product-description text-overflow', className)}>
      {TextWithLineBreaks(text)}
      {addDots ? '...' : ''}
    </p>
  );
};

export default ProductDescription;
