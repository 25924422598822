'use client';

import type { AnchorHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import { useMemo } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { useNavContext } from '../context';

type PropTypes = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  children: ReactNode;
  plainLink?: boolean;
};

const NavItem: FC<PropTypes> = ({ href = '', className, children, plainLink = false, ...rest }) => {
  const { toggleNavCollapsed, isNavItemActive } = useNavContext();

  const active = useMemo(() => {
    return isNavItemActive(href);
  }, [href, isNavItemActive]);

  const inner = (
    <span {...rest} className={clsx('nav-link', className, { active })}>
      {children}
    </span>
  );

  return (
    <div onClick={() => toggleNavCollapsed(false)}>
      {plainLink ? (
        <a className="logo-link" href={href}>
          {inner}
        </a>
      ) : (
        <Link className="logo-link" href={href} prefetch={false}>
          {inner}
        </Link>
      )}
    </div>
  );
};

export default NavItem;
