import type { AnchorHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';

type PropTypes = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  sameTab?: boolean;
  noFollow?: boolean;
  children: ReactNode;
};

const LinkExternal: FC<PropTypes> = ({ href, sameTab, noFollow, children, ...args }) => {
  return (
    <a
      {...args}
      href={href}
      target={sameTab ? '_self' : '_blank'}
      rel={`noopener noreferrer ${noFollow ? 'nofollow' : ''}`}
    >
      {children}
    </a>
  );
};

export default LinkExternal;
