import type { FC } from 'react';
import Image from 'next/image';

import { IconPropType } from './IconPropType';

const HeaderIcon: FC<IconPropType> = ({ alt, className, img, width, height }) => {
  return (
    <Image alt={alt} className={className} src={img} width={width} height={height} quality="70" />
  );
};

export default HeaderIcon;
