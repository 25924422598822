import type { RefObject } from 'react';
import { useEffect } from 'react';

export const useOutsideClick = <T extends HTMLElement = HTMLElement>({
  ref,
  callback,
  active,
}: {
  ref: RefObject<T>;
  callback: () => void;
  active?: boolean;
}) => {
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    if (active) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, active]);
};

export default useOutsideClick;
