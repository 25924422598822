'use client';

import { type FC, useState } from 'react';
import clsx from 'clsx';

import SmartLink from '@hyex/modules/shared/base/Link';
import ProductDescription from '@hyex/modules/shared/Product/common/ProductDescription';
import ProductImage from '@hyex/modules/shared/Product/common/ProductImage';
import ProductPrice from '@hyex/modules/shared/Product/common/ProductPrice';
import ProductQuantityPicker from '@hyex/modules/shared/Product/common/ProductQuantityPicker';
import { DisplayedProduct } from '@hyex/modules/shared/Product/base/DisplayedProduct';

type PropTypes = {
  product: DisplayedProduct;
  productBlockWidth?: number;
  onDeleted?: (sku: string) => void;
  parenctClassName?: string;
};

const ProductItemCart: FC<PropTypes> = ({
  product,
  parenctClassName,
  productBlockWidth = 0,
  onDeleted,
}) => {
  const [amount, setAmount] = useState(1);

  const style = {
    block: productBlockWidth !== 0 ? { width: productBlockWidth } : {},
  };

  return (
    <div className={clsx('product-block', parenctClassName)} style={style.block}>
      <div className="product-info-block">
        <h2 className="product-name">
          <SmartLink
            category={{
              path: `/store/${product.originalSkuId}`,
            }}
            isMobile={false}
            className="title" //todo: ?
            text={product.title}
            withLink={false}
          />
        </h2>
        <ProductDescription
          description={product.description}
          shortDescription={product.shortDescription}
        />
        {/* {product.autoship ? (
          <p className="product-delivered">Autoship</p>
        ) : (
          <p className="product-delivered">Delivered Once</p>
        )} */}
        <ProductPrice
          onSale={product?.isOnSale ?? false}
          price={product.price}
          salePrice={product.price}
        />
        <ProductQuantityPicker
          onlyDelete={true}
          onNewValue={setAmount}
          disabled={false}
          onDelete={() => onDeleted(product.sku)}
          defaultQuantity={product.quantity}
        />
      </div>
      <ProductImage
        sku={product.originalSkuId}
        onSale={product?.isOnSale ?? false}
        image={product?.images?.single ?? ''}
        withLink={false}
        className="center-cart"
        alt={product.title}
      />
    </div>
  );
};

export default ProductItemCart;
