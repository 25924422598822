import type { FC } from 'react';
import type { ImageProps } from 'next/image';
import Image from 'next/image';
import Link from 'next/link';

type PropTypes = {
  onSale: boolean;
  image: string | ImageProps['src'];
  quality?: any;
  withLink?: boolean;
  withBadge?: boolean;
  sku?: string;
  className?: string;
  fill?: boolean;
  raw?: boolean;
  alt: string;
  width?: number;
  height?: number;
};

const ProductImage: FC<PropTypes> = ({
  sku,
  onSale,
  image,
  className,
  fill = false,
  withLink = true,
  quality = '70',
  raw = false,
  alt,
  withBadge = true,
  width,
  height,
}) => {
  const productImage = () => (
    <Image
      alt={alt}
      src={image}
      width={width ?? 370}
      height={height ?? 400}
      quality={quality}
      className={className}
      // sizes="50vw"
      // fill={fill}
      priority={true}
      loading="eager"
    />
  );

  if (!image) {
    return <></>;
  }

  if (raw) {
    return productImage();
  }

  return (
    <>
      <div className="product-img-block">
        <div className="product-img-block-wrapper">
          {withLink ? (
            <>
              <Link href={`/store/${sku}`}>{productImage()}</Link>
            </>
          ) : (
            <>{productImage()}</>
          )}
        </div>
      </div>
      {onSale && (
        <div className="badges-block">
          <div className="product-badge discontinued">Discontinued</div>
          <div className="product-badge">on sale</div>
        </div>
      )}
    </>
  );
};

export default ProductImage;
