'use client';

import type { FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useNavContext } from '../context';

type PropTypes = {
  className?: string;
  children: ReactNode;
};

const NavList: FC<PropTypes> = ({ className, children }) => {
  const { navCollapsed } = useNavContext();

  return (
    <div
      className={clsx('nav-menu-list', className, {
        active: navCollapsed,
      })}
    >
      {children}
    </div>
  );
};

export default NavList;
