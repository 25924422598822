import type { FC } from 'react';

import type { Article, ArticlePreview } from '@hyex/dxp/types/blog/Article';
import type { BlogCategory } from '@hyex/dxp/types/blog/Category';
import LinkExternal from '@hyex/ui/LinkExternal';
import NavItem from '@hyex/ui/nav/NavItem';
import NavSubmenuItem from '@hyex/ui/nav/NavSubmenuItem';
import { ProductCategory } from '@hyex/dxp/types/store/Product/ProductCategory';
import SubmenuItem from '@hyex/modules/shared/Nav/SubmenuItem';
import { ProductPreview } from '@hyex/dxp/types/store/Product/Product';

type PropTypes = {
  latestArticles?: Article[] | ArticlePreview[];
  products?: ProductPreview[];
  blogCategories?: BlogCategory[];
  productCategories?: ProductCategory[];
};

const PortalHeaderNav: FC<PropTypes> = ({
  products,
  latestArticles,
  blogCategories,
  productCategories,
}) => {
  return (
    <>
      <SubmenuItem<BlogCategory, ArticlePreview>
        href={'/learn/category'}
        subHref="/learn/article"
        indexHref="/learn"
        title={'Learn'}
        subTitle={'Articles'}
        items={blogCategories || []}
        subItems={latestArticles || []}
        viewAllText="View All Articles"
      />

      <SubmenuItem<ProductCategory, ProductPreview>
        items={productCategories || []}
        subItems={products?.slice(0, 4) || []}
        title="Shop"
        href="/store"
        subHref="/store"
        indexHref="/store"
        subTitle="Products"
        viewAllText="View All Products"
      />

      <NavSubmenuItem title="About" href="/about-us">
        <div className="nav-full-width line">
          <NavItem href="/contact-us">Contact Us</NavItem>
          <NavItem href="/quality-statement">Quality Statement</NavItem>
          <NavItem href="/our-story">Our Story</NavItem>
          <NavItem href="/faqs">FAQs</NavItem>
        </div>
      </NavSubmenuItem>

      <div className="nav-submenu-link-wrapper">
        <LinkExternal href={process.env.NEXT_PUBLIC_MY_ACCOUNT} className="nav-link">
          My Account
        </LinkExternal>
      </div>
    </>
  );
};

export default PortalHeaderNav;
