import HeaderIcon from '@hyex/modules/shared/Header/HeaderIcon';
import { COMPANY_NAME } from '@hyex/modules/portal/constants';
import iconImg from 'public/images/gv-icon.svg';
import logoImg from 'public/images/gv-logo.svg';

export const PortalIcon = (
  <HeaderIcon alt={COMPANY_NAME} className={'logo'} img={iconImg} width={45} height={27} />
);

export const PortalLogo = (
  <HeaderIcon alt={COMPANY_NAME} className={'logo'} img={logoImg} width={122} height={64} />
);
