export function createCookie(name: string, value: string, days: number, domainUrl?: string) {
  let expires = '';

  if (days) {
    const date = new Date();

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    expires = `; expires=${date.toUTCString()}`;
  }

  if (domainUrl) {
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/; domain=${domainUrl}`;
  } else {
    document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
  }
}
