import type { ProductVariantsCarousel } from '@hyex/dxp/types/domain/ProductVariantsCarousel';
import type { ProductVariantType } from '@hyex/dxp/types/domain/ProductVariantType';
import type { Product } from '@hyex/dxp/types/store/Product/Product';

import { PRODUCT_ALLERGEN_KEY } from '../constants';

export function getAllergens(product: Product): string[] {
  return Object.entries(product?.allergens ?? {})
    .filter(([key, value]) => value[PRODUCT_ALLERGEN_KEY] === true)
    .map(([key, value]) => key);
}

export function getImages(product: Product): string[] {
  const order = ['single', 'double', 'triple', 'pack'];

  if (!product?.images) return [];

  const sortedKeys = Object.keys(product.images).sort((a, b) => {
    const indexOfA = order.indexOf(a);

    const indexOfB = order.indexOf(b);

    if (indexOfA !== -1 && indexOfB !== -1) {
      return indexOfA - indexOfB;
    }

    if (indexOfA !== -1) {
      return -1;
    }

    if (indexOfB !== -1) {
      return 1;
    }

    return 0;
  });

  return sortedKeys
    .map((key: string) => (product.images as any)[key])
    .filter((c) => !!c)
    .filter((c) => c.includes('https://'));
}

export function convertPrice(price: string): number {
  if (!price) return 0;

  return Number(price.replace('$', ''));
}

export function priceFormat(price: string, amount: number, autoship: boolean): string {
  return autoship
    ? ((convertPrice(price) * amount) / 2).toFixed(2)
    : (convertPrice(price) * amount).toFixed(2);
}

export function createProductVariantsCarousel(product: Product): ProductVariantsCarousel {
  const autoSkus = product?.skus?.filter((d) => !!d.autoship) || [];

  const autoshipVariants = autoSkus
    .map((sku) => {
      return {
        originalSku: product.sku,
        skuId: sku.autoship.sku,
        skuText: sku.autoship.title,
        price: sku.autoship.price,
        image: sku.image,
        autoship: true,
        isOnSale: sku.isOnSale,
        oldPrice: sku.oldPrice,
        description: product?.shortDescription ?? product.description,
        productName: product.title,
      };
    })
    .filter((d) => d.skuId !== '');

  const oneTimeVariants: ProductVariantType[] = product?.skus
    ? product.skus
        .map((sku) => {
          return {
            originalSku: product.sku,
            skuId: sku.sku,
            skuText: sku.title,
            price: sku.price,
            image: sku.image,
            autoship: false,
            isOnSale: sku.isOnSale,
            oldPrice: sku.oldPrice,
            description: product?.shortDescription ?? product.description,
            productName: product.title,
          };
        })
        .filter((d) => d.skuId !== '')
    : [];

  const autoshipImages = autoshipVariants.map((d) => d.image).filter((img) => !!img);

  const oneTimeImages = oneTimeVariants.map((d) => d.image).filter((img) => !!img);

  const hasSupplementImage = !!product?.images?.supplementFacts;

  const hasInstructionImage = !!product?.images?.instructions;

  if (product.images?.instructions) {
    autoshipImages.push(product.images.instructions);

    oneTimeImages.push(product.images.instructions);
  }

  if (product.images?.supplementFacts) {
    autoshipImages.push(product.images.supplementFacts);

    oneTimeImages.push(product.images.supplementFacts);
  }

  const autoshipSlides = Array.from(Array(autoshipImages.length).keys());

  const oneTimeSlides = Array.from(Array(oneTimeImages.length).keys());

  return {
    autoshipVariants: autoshipVariants,
    autoshipImages: autoshipImages,
    autoshipSlides: autoshipSlides,
    defaultAutoshipVariant: autoshipVariants[0],

    oneTimeVariants: oneTimeVariants,
    oneTimeImages: oneTimeImages,
    oneTimeSlides: oneTimeSlides,
    defaultOneTimeVariant: oneTimeVariants[0],

    hasSupplementImage: hasSupplementImage,
    hasInstructionImage: hasInstructionImage,
  };
}

export const removeLastCharIfDot = (str: string) => (str.endsWith('.') ? str.slice(0, -1) : str);

export function articleFormat(text: string) {
  text = text.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 200) + '...';

  text = text.replace(/<br\s*\/?>/gi, '\n');

  text = text.replace(/<[^>]*>/g, '');

  return text.slice(0, 200) + '...';
}

export function isDefault(value: any) {
  return value === undefined || value === null || value === '';
}

export function mergeItemsInCart(existedItemsInCart: any[], newItemToCart: any) {
  if (!newItemToCart || !newItemToCart.skuId) {
    console.error('Invalid new item to add to cart');

    return existedItemsInCart;
  }

  const updatedItems =
    existedItemsInCart && existedItemsInCart.length > 0
      ? [...existedItemsInCart, { item_id: newItemToCart.skuId }]
      : [{ item_id: newItemToCart.skuId }];

  return updatedItems;
}

export function hasKey<T extends Object>(obj: T, key: keyof any): key is keyof T {
  return key in obj;
}
