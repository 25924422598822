'use client';

import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';

import NavProvider from '../context';

type PropTypes = {
  className?: string;
  children: ReactNode;
};

const Navbar: FC<PropTypes> = ({ className, children }) => {
  const pathname = usePathname();

  const [navCollapsed, setNavCollapsed] = useState(false);

  const isNavItemActive = useCallback(
    (navItemPath = '') => {
      const navItemBase = navItemPath.split('/')[1];

      const pageBase = pathname.split('/')[1];

      return navItemBase === pageBase;
    },
    [pathname]
  );

  const toggleNavCollapsed = useCallback(
    (force?: boolean) => {
      setNavCollapsed((v) => (force !== undefined ? force : !v));
    },
    [setNavCollapsed]
  );

  return (
    <NavProvider
      values={{
        navCollapsed,
        isNavItemActive,
        toggleNavCollapsed,
      }}
    >
      <nav className={clsx('nav', className)}>{children}</nav>
    </NavProvider>
  );
};

export default Navbar;
