'use client';

import { type FC, useState } from 'react';

import type { Article, ArticlePreview } from '@hyex/dxp/types/blog/Article';
import type { BlogCategory } from '@hyex/dxp/types/blog/Category';
import cart from '/public/images/icons/cart-icon.svg';

import { ProductCategory } from '@hyex/dxp/types/store/Product/ProductCategory';
import SiteSearch from '@hyex/modules/shared/site-search/SiteSearch';
import NavButton from '@hyex/ui/nav/NavButton';
import Image from 'next/image';
import { NavBase } from '@hyex/modules/shared/Nav/NavBase';
import PortalHeaderNav from '../HeaderNav';
import { PortalIcon, PortalLogo } from '../logo';
import { ProductPreview } from '@hyex/dxp/types/store/Product/Product';

type PropType = {
  articles: ArticlePreview[];
  products: ProductPreview[];
  blogCategories: BlogCategory[];
  productCategories: ProductCategory[];
};

const PortalSuspendedHeader: FC<PropType> = ({
  products,
  articles,
  blogCategories,
  productCategories,
}) => {
  const [itemsInCart, setItemsInCart] = useState(0);

  const rightSideItems = (
    <>
      <SiteSearch baseHref={'/learn/search'} />
      <NavButton className="cart-btn" onClick={() => {}}>
        <div className="relative">
          <span className="cart-btn-items">{itemsInCart}</span>
          <Image src={cart} alt={'Your cart'} width={34} height={34} />
        </div>
      </NavButton>
    </>
  );

  const leftSideItems = (
    <PortalHeaderNav
      latestArticles={articles}
      blogCategories={blogCategories}
      productCategories={productCategories}
      products={products}
    />
  );

  return (
    <>
      <header>
        <div className="container">
          <NavBase
            logo={PortalLogo}
            icon={PortalIcon}
            beforeLogo={leftSideItems}
            afterLogo={rightSideItems}
          />
        </div>
      </header>
    </>
  );
};

export default PortalSuspendedHeader;
