'use client';

import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';

import useIsMobile from '@hyex/utils/hooks/useIsMobile';
import useOutsideClick from '@hyex/utils/hooks/useOutsideClick';

import NavProvider from '../context';

type PropTypes = {
  className?: string;
  isMobileWith: number;
  children: ReactNode;
};

const Navbar: FC<PropTypes> = ({ className, isMobileWith, children }) => {
  const navRef = useRef(null);

  const pathname = usePathname();

  const [navCollapsed, setNavCollapsed] = useState(false);

  const isMobile = useIsMobile(isMobileWith);

  const closeNav = useCallback(() => {
    setNavCollapsed(false);
  }, []);

  useOutsideClick({ ref: navRef, callback: closeNav, active: isMobile });

  // close nav if user resized screen
  useEffect(() => {
    closeNav();
  }, [isMobile, closeNav]);

  const isNavItemActive = useCallback(
    (navItemPath = '') => {
      const navItemBase = navItemPath.split('/')[1];

      const pageBase = pathname.split('/')[1];

      return navItemBase === pageBase;
    },
    [pathname]
  );

  const toggleNavCollapsed = useCallback(
    (force?: boolean) => {
      setNavCollapsed((v) => (force !== undefined ? force : !v));
    },
    [setNavCollapsed]
  );

  return (
    <NavProvider
      values={{
        navCollapsed,
        isNavItemActive,
        toggleNavCollapsed,
      }}
    >
      <nav ref={navRef} className={clsx('nav', className)}>
        {children}
      </nav>
    </NavProvider>
  );
};

export default Navbar;
