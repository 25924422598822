'use client';

import { ReactNode, type FC, useEffect } from 'react';
import NavBar from '@hyex/ui/nav/NavBar';
import NavItem from '@hyex/ui/nav/NavItem';
import NavList from '@hyex/ui/nav/NavList';
import NavWrapper from '@hyex/ui/nav/NavWrapper';
import useIsMobile from '@hyex/utils/hooks/useIsMobile';

export type NavBasePropsType = {
  logo: ReactNode;
  icon: ReactNode;
  beforeLogo: ReactNode;
  afterLogo: ReactNode;
  notFound?: boolean;
};

export const NavBase: FC<NavBasePropsType> = ({
  logo,
  icon,
  beforeLogo,
  afterLogo,
  notFound = false,
}) => {
  const isMobile = useIsMobile(481);

  return (
    <NavBar isMobileWith={1099}>
      <NavItem className="logo" href="/" plainLink={notFound}>
        {isMobile ? icon : logo}
      </NavItem>
      <NavWrapper>
        <NavList>{beforeLogo}</NavList>

        <div className="header-wrapper">{afterLogo}</div>
      </NavWrapper>
    </NavBar>
  );
};
