'use client';

import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import type { Product } from '@hyex/dxp/types/store/Product/Product';
import { isCookieEnabled } from '@hyex/modules/utils/cookie/isCookieEnabled';
import { doesCookieExist } from '@hyex/modules/utils/cookie/doesCookieExist';
import type UcCartItem from '@hyex/modules/portal/ultracart/types/UcCartItem';
import UltraCartService from '@hyex/modules/portal/ultracart/uc.cart.service';

import ProductItemCart from './product-item';
import { COOKIE_DOMAIN, COUPON_COOKIE_NAME } from '@hyex/modules/portal/constants';
import { getCookieValue } from '@hyex/modules/utils/cookie/getCookieValue';
import { eraseCookie } from '@hyex/modules/utils/cookie/eraseCookie';

import Link from 'next/link';

const CHECKOUT_URL = process.env.NEXT_PUBLIC_CHECKOUT_URL;

type CartPropType = {
  render?: boolean;
};
const Cart: FC<CartPropType> = ({ render }) => {
  const [ucCartItems, setUcCartItems] = useState<UcCartItem[]>([]);

  const [relatedProducts, setRelatedProducts] = useState<Product[]>([]);

  const [itemsBeingRemoved, setItemsBeingRemoved] = useState(new Set());

  const [newlyAddedItems, setNewlyAddedItems] = useState(new Set());

  const [cookieEnabled, setCookieEnabled] = useState(true);

  const cartAnimationEffects = (skuId: string) =>
    clsx({
      'item-exit': itemsBeingRemoved.has(skuId),
      'item-enter': newlyAddedItems.has(skuId),
    });

  const isWindowExist = typeof window !== 'undefined';

  function refreshRelatedProducts(items: any[]) {
    const relatedIds = items
      .map((d) => d.relatedProducts)
      .flatMap((c) => c)
      .filter((d) => !!d);

    const uniqueRelatedIds = [...new Set(relatedIds)];

    setRelatedProducts(uniqueRelatedIds);
  }

  const totalPrice = useMemo(() => {
    let sum = 0;

    ucCartItems?.forEach((item) => {
      sum += item.total_cost_with_discount.value;
    });

    return sum.toFixed(2);
  }, [ucCartItems]);

  useEffect(() => {
    if (!isWindowExist) return;

    const handleCartChange = (beforeStateNull: boolean) => {
      setUcCartItems(UltraCartService.getItems());
    };

    UltraCartService.subscribe(handleCartChange);

    return () => UltraCartService.unsubscribe(handleCartChange);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    setCookieEnabled(isCookieEnabled());

    const cookieExist = doesCookieExist(window?.uc?.cookieName);

    const cartId = cookieExist ? getCookieValue(window.uc.cookieName) : undefined;

    UltraCartService.getCart(
      { expand: 'items' },
      ({ data, isExisted }) => {
        if (!data || Object.keys(data).length === 0) {
          alert('empty data');

          return;
        }

        window.uc.cart = data.cart;

        setUcCartItems(data.cart.items);
      },
      (error) => {
        // console.error(error);
      },
      cartId
    );

    refreshRelatedProducts([]);
  }, [isWindowExist && window?.uc?.checkoutApi]);

  const handleDelete = (skuId: string) => {
    setItemsBeingRemoved(new Set(itemsBeingRemoved.add(skuId)));

    const updatedCartItems = ucCartItems.filter((item) => item.item_id !== skuId);

    window.uc.cart.items = updatedCartItems;

    UltraCartService.updateUcCart(
      window.uc.cart,
      { expand: 'items' },
      (data) => {
        setUcCartItems(window.uc.cart.items);

        setItemsBeingRemoved(new Set([...itemsBeingRemoved].filter((id) => id !== skuId)));
      },
      (error) => {
        // console.error(error);
      }
    );
  };

  function redirectToCheckoutUrl() {
    const cookieValue = getCookieValue(COUPON_COOKIE_NAME);

    eraseCookie(COUPON_COOKIE_NAME, COOKIE_DOMAIN);

    location.href = CHECKOUT_URL + (cookieValue ? '&coupon=' + cookieValue : '');
  }

  const ucItems = ucCartItems?.map((item: UcCartItem, i) => {
    return (
      <ProductItemCart
        parenctClassName={cartAnimationEffects(item.item_id)}
        key={i + item.item_id + item.item_oid}
        product={{
          originalSkuId: 'N/A',
          sku: item.item_id,

          title: item.item_id,
          description: item.description,
          shortDescription: null,
          price: item.total_cost.value + '',
          isOnSale: false,
          images: {
            single: item.default_image_url,
          },
          autoship: false,
          quantity: item.quantity,
        }}
        onDeleted={handleDelete}
      />
    );
  });

  if (!render) return <></>;

  return (
    <div className="cart-component">
      <div className="cart-header">
        <span className="cart-title">Your Cart</span>
      </div>
      <div className="product-list">{ucItems}</div>
      {/* <CartRecommendations references={relatedProducts} /> */}
      <div className="subtotal-block">
        <div className="subtotal-wrapper">
          <h3>Subtotal</h3>
          <span className="total-price">${totalPrice}</span>
        </div>
      </div>

      {isWindowExist && getCookieValue(COUPON_COOKIE_NAME) && (
        <div>
          <p className="center">
            Coupon code &nbsp;
            <b>{getCookieValue(COUPON_COOKIE_NAME)}</b> &nbsp;will be applied at checkout
          </p>
        </div>
      )}

      <div className="cart-footer">
        {!cookieEnabled && (
          <div className="cookie-error">
            <p>
              Cookies must be enabled to place orders via our webstore. To place an order by phone,
              contact our customer service team at tel <a href="tel:8008931694">8008931694</a>
            </p>
          </div>
        )}
        <div className="buttons-block">
          <a className="button outline sumbit" href="/store">
            continue shopping
          </a>

          <button className="colored sumbit" onClick={redirectToCheckoutUrl}>
            continue to checkout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
