export function isCookieEnabled() {
  if (typeof navigator === 'undefined') {
    return false;
  }

  if (navigator.cookieEnabled) return true;

  document.cookie = 'cookietest=1';

  const ret = document.cookie.indexOf('cookietest=') != -1;

  document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';

  return ret;
}
