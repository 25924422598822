import { type FC, useState } from 'react';

import Icon from '@hyex/modules/shared/Icon';
import { ICON_DELETE, ICON_MINUS, ICON_PLUS } from '@hyex/modules/shared/Icon/SVG';

type PropTypes = {
  disabled?: boolean;
  onNewValue: (value: number) => void;
  onlyDelete?: boolean;
  onDelete?: () => void;
  defaultQuantity?: number;
};

const ProductQuantityPicker: FC<PropTypes> = ({
  onNewValue,
  disabled = false,
  onlyDelete = false,
  onDelete,
  defaultQuantity,
}) => {
  const [quantity, setQuantity] = useState(defaultQuantity ?? 1);

  const set = (action: string) => {
    if (action === '+') {
      const value = quantity + 1 < 9 ? quantity + 1 : 9;

      setQuantity(value);

      onNewValue(value);
    } else {
      const value = quantity - 1 >= 1 ? quantity - 1 : 1;

      setQuantity(quantity - 1 >= 1 ? quantity - 1 : 1);

      onNewValue(value);
    }
  };

  if (onlyDelete) {
    return (
      <div className="quantity-selector">
        <Icon
          icon={ICON_DELETE}
          className="quantity-selector-btn link"
          clickable={true}
          enabled={true}
          onClick={onDelete}
        />
        {/* <span className="current-quantity">{quantity}</span> */}
      </div>
    );
  }

  return (
    <>
      <div className="quantity-selector">
        <Icon
          icon={ICON_MINUS}
          enabled={!disabled}
          className="quantity-selector-btn link"
          onClick={() => set('-')}
          clickable={true}
        />
        <span className="current-quantity">{quantity}</span>
        <Icon
          icon={ICON_PLUS}
          enabled={!disabled}
          className="quantity-selector-btn link"
          onClick={() => set('+')}
          clickable={true}
        />
      </div>
    </>
  );
};

export default ProductQuantityPicker;
