export const ICON_MINUS = (
  <svg className="icon-minus" viewBox="0 0 16 2">
    <path
      d="M1,1 L15,1"
      stroke="#282c2f"
      strokeWidth="1.5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    ></path>
  </svg>
);

export const ICON_PLUS = (
  <svg className="icon-plus" viewBox="0 0 16 16">
    <g stroke="#282c2f" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M8,1 L8,15"></path>
      <path d="M1,8 L15,8"></path>
    </g>
  </svg>
);

export const ICON_ACCORDION = (
  <div className="accordion-icon">
    <svg viewBox="0 0 16 16">
      <g stroke="#282c2f" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
        <path d="M8,1 L8,15"></path>
        <path d="M1,8 L15,8"></path>
      </g>
    </svg>
  </div>
);

export const ICON_DELETE = (
  <svg className="delete-icon" viewBox="0 0 17 18.5">
    <g stroke="#282c2f" strokeWidth="1.5" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M1293,218h17" transform="translate(-1293 -214.25)" />
      <path
        d="M1308,219v11a2,2,0,0,1-2,2h-9a2,2,0,0,1-2-2V219"
        transform="translate(-1293 -214.25)"
      />
      <path d="M1298,217a2,2,0,0,1,2-2h3a2,2,0,0,1,2,2" transform="translate(-1293 -214.25)" />
      <path d="M1300,221v8" transform="translate(-1293 -214.25)" />
      <path d="M1303,221v8" transform="translate(-1293 -214.25)" />
    </g>
  </svg>
);

export const ICON_INFO = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50px" height="50px">
    <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
  </svg>
);

export const ICON_YES = (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
    <path
      d="M1 7.89642L5.10526 12.0017L14 1.73853"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="round"
    ></path>
  </svg>
);

export const ICON_CLOSE = (
  <svg
    className="small-close-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="35.66"
    height="36"
    viewBox="0 0 35.66 36"
  >
    <path
      stroke="#282c2f"
      strokeWidth="5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      d="M2686.74,79.79l-32.43-32.451"
      transform="translate(-2652.5 -45.578)"
    />
    <path
      stroke="#282c2f"
      strokeWidth="5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      d="M2653.92,79.79l32.43-32.451"
      transform="translate(-2652.5 -45.578)"
    />
  </svg>
);

export const PRINTER_ICON = (
  <svg
    fill="#ffffff"
    height="800px"
    width="800px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    enable-background="new 0 0 64 64"
  >
    <g id="Printer">
      <path
        d="M57.7881012,14.03125H52.5v-8.0625c0-2.2091999-1.7909012-4-4-4h-33c-2.2091999,0-4,1.7908001-4,4v8.0625H6.2119002
		C2.7871001,14.03125,0,16.8183498,0,20.2431507V46.513649c0,3.4248009,2.7871001,6.2119026,6.2119002,6.2119026h2.3798995
		c0.5527,0,1-0.4472008,1-1c0-0.5527-0.4473-1-1-1H6.2119002C3.8896,50.7255516,2,48.8359489,2,46.513649V20.2431507
		c0-2.3223,1.8896-4.2119007,4.2119002-4.2119007h51.5762024C60.1102982,16.03125,62,17.9208508,62,20.2431507V46.513649
		c0,2.3223-1.8897018,4.2119026-4.2118988,4.2119026H56c-0.5527992,0-1,0.4473-1,1c0,0.5527992,0.4472008,1,1,1h1.7881012
		C61.2128983,52.7255516,64,49.9384499,64,46.513649V20.2431507C64,16.8183498,61.2128983,14.03125,57.7881012,14.03125z
		 M13.5,5.96875c0-1.1027999,0.8971996-2,2-2h33c1.1027985,0,2,0.8972001,2,2v8h-37V5.96875z"
      />
      <path
        d="M44,45.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,47.0302505,20,47.0302505h24
		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,45.0322495,44,45.0322495z"
      />
      <path
        d="M44,52.0322495H20c-0.5517998,0-0.9990005,0.4472008-0.9990005,0.9990005S19.4482002,54.0302505,20,54.0302505h24
		c0.5517006,0,0.9990005-0.4472008,0.9990005-0.9990005S44.5517006,52.0322495,44,52.0322495z"
      />
      <circle cx="7.9590998" cy="21.8405495" r="2" />
      <circle cx="14.2856998" cy="21.8405495" r="2" />
      <circle cx="20.6121998" cy="21.8405495" r="2" />
      <path d="M11,62.03125h42v-26H11V62.03125z M13.4036999,38.4349518h37.1925964v21.1925964H13.4036999V38.4349518z" />
    </g>
  </svg>
);

export const BACK_ARROW_ICON = (w = 54.8, h = 22.8) => (
  <svg
    id="back-arr"
    xmlns="http://www.w3.org/2000/svg"
    width={w}
    height={h}
    viewBox="0 0 54.8 22.812"
  >
    <path
      fill="none"
      stroke="#5aac1c"
      strokeWidth={'4px'}
      fillRule="evenodd"
      d="M10976,912h-50"
      transform="translate(-10921.2 -900.594)"
    />
    <path
      fill="none"
      stroke="#5aac1c"
      strokeWidth={'4px'}
      fillRule="evenodd"
      d="M10934,902l-10,10,10,10"
      transform="translate(-10921.2 -900.594)"
    />
  </svg>
);
