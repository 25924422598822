import { useEffect, useState } from 'react';
import _merge from 'lodash/merge';

// https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe#parameters
const DEFAULT_OPTIONS: MutationObserverInit = {
  subtree: false,
  childList: false,
  attributes: false,
  attributeOldValue: false,
  characterData: false,
  characterDataOldValue: false,
};

export const useMutationObservable = (
  targetEl: Node | null,
  cb: (recordsList: MutationRecord[], observer: MutationObserver) => void,
  options: MutationObserverInit
) => {
  const _options = _merge(DEFAULT_OPTIONS, options);

  const [observer, setObserver] = useState<MutationObserver | null>(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);

    setObserver(obs);
  }, [cb, setObserver]);

  useEffect(() => {
    if (!observer || !targetEl) {
      return;
    }

    observer.observe(targetEl, _options);

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, targetEl, _options]);
};

export default useMutationObservable;
