import { useEffect, useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const propTypes = {
  layout: PropTypes.oneOf(['', 'page', 'line']),
  children: PropTypes.node,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

const SiteSearchForm = ({ layout = 'page', children, className, onSubmit }) => {
  const searchInput = useRef(null);

  useEffect(() => {
    searchInput.current.focus();
  });

  return (
    <div className={clsx('site-search-form', className)}>
      <form layout={layout} className="form-inline" onSubmit={onSubmit}>
        <input ref={searchInput} type="text" name="term" className="" placeholder="Search..." />

        <button
          type="submit"
          variant={layout === 'line' ? 'default' : 'primary'}
          className="btn primary-form-action"
        >
          {layout === 'page' && 'Search'}
        </button>
        {children}
      </form>
    </div>
  );
};

SiteSearchForm.propTypes = propTypes;

export default SiteSearchForm;
