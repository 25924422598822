import type { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import clsx from 'clsx';

import { useNavContext } from '../context';

type PropTypes = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  children: ReactNode;
  onClick: () => void;
};

const NavItem: FC<PropTypes> = ({ onClick, className, children, ...rest }) => {
  const { toggleNavCollapsed } = useNavContext();

  return (
    <button
      {...rest}
      onClick={() => {
        toggleNavCollapsed(false);

        onClick();
      }}
      className={clsx('link', className)}
    >
      {children}
    </button>
  );
};

export default NavItem;
