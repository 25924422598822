'use client';

import useWindowSize from './useWindowSize';

export const useIsMobile = (size: number): boolean => {
  const windowSize = useWindowSize();

  return !!(windowSize.width && windowSize.width < size);
};

export default useIsMobile;
