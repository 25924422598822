'use client';

import { useCallback, useRef, useState } from 'react';
import { useRouter } from 'next/navigation';
import clsx from 'clsx';

import { useOutsideClick } from '@hyex/utils/hooks/useOutsideClick';

import SearchFormWrapper from './components/search-form-wrapper/SearchFormWrapper';

import NavButton from '@hyex/ui/nav/NavButton';
import Image from 'next/image';
import searchIcon from '/public/images/icons/search-icon.svg';

const SiteSearch = ({ baseHref }) => {
  const { push } = useRouter();

  const [showSearch, setShowSearch] = useState(false);

  const wrapperRef = useRef(null);

  const toggleSearch = useCallback((e) => {
    if (e) {
      e.preventDefault();
    }

    setShowSearch((current) => !current);
  }, []);

  const submitSearch = useCallback(
    (e) => {
      e.preventDefault();

      setShowSearch(false);

      if (e.target.term.value) {
        push(`${baseHref}${encodeURIComponent(e.target.term.value)}`);
      }
      e.target.term.value = '';
    },
    [push]
  );

  useOutsideClick(wrapperRef, submitSearch);

  return (
    <div ref={wrapperRef} className={clsx('site-search', { shown: showSearch })}>
      <NavButton className={''} onClick={toggleSearch} aria-label="Search">
        <Image src={searchIcon} alt={''} width={22} height={32} />
      </NavButton>

      <SearchFormWrapper toggleSearch={toggleSearch} onSubmit={submitSearch} />
    </div>
  );
};

export default SiteSearch;
