export function doesCookieExist(cookieName: string) {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(cookieName + '=')) {
      return true;
    }
  }

  return false;
}
