'use client';

import type { AnchorHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { useNavContext } from '../context';

type PropTypes = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  title: string;
  children: ReactNode;
};

const NavSubmenuItem: FC<PropTypes> = ({ title, href = '', className, children }) => {
  const { navCollapsed, toggleNavCollapsed, isNavItemActive } = useNavContext();

  const [itemCollapsed, setItemCollapsed] = useState(false);

  const active = useMemo(() => {
    return isNavItemActive(href);
  }, [href, isNavItemActive]);

  const handleCollapsed = () => {
    toggleNavCollapsed(false);

    setItemCollapsed((v) => !v);
  };

  return (
    <div
      className="nav-submenu-link-wrapper"
      onClick={handleCollapsed}
      onMouseEnter={() => setItemCollapsed(true)}
      onMouseLeave={() => setItemCollapsed(false)}
    >
      <Link href={href} className={clsx('nav-link', className, { active })}>
        {title}
      </Link>
      {navCollapsed || itemCollapsed ? <div className="submenu-wrapper">{children}</div> : null}
    </div>
  );
};

export default NavSubmenuItem;
