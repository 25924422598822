import type { FC, ReactNode } from 'react';
import clsx from 'clsx';

import LinkExternal from '../../LinkExternal';
import { useNavContext } from '../context';

type PropTypes = {
  className?: string;
  children: ReactNode;
};

//TODO: Idea is good, but need to improve
const NavWrapper: FC<PropTypes> = ({ className, children }) => {
  const { navCollapsed, toggleNavCollapsed } = useNavContext();

  return (
    <div className={clsx('nav-wrapper', className)}>
      {children}
      <div
        onClick={() => toggleNavCollapsed()}
        className={clsx('nav-menu-bar', { active: navCollapsed })}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <LinkExternal href={process.env['NEXT_PUBLIC_MY_ACCOUNT']} className="nav-link acc-link">
        My Account
      </LinkExternal>
    </div>
  );
};

export default NavWrapper;
