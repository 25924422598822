import { useCallback, useReducer } from 'react';

import type * as ModalDialog from './types';

const reducer = (state: ModalDialog.State, action: ModalDialog.Action): ModalDialog.State => {
  switch (action.type) {
    case 'open':
      return { opened: true, context: action.payload };

    case 'close':
      return { opened: false };

    default:
      return state;
  }
};

export const useModalDialog = (): ModalDialog.Dialog => {
  const [state, dispatch] = useReducer(reducer, {
    opened: false,
  });

  const open: ModalDialog.ModalHandler = useCallback((context) => {
    dispatch({
      type: 'open',
      payload: typeof context === 'object' && context['nativeEvent'] ? undefined : context,
    });
  }, []);

  const close = useCallback(() => dispatch({ type: 'close' }), []);

  return { open, close, ...state };
};

export default useModalDialog;
