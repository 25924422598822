import { convertPrice } from '@hyex/modules/portal/domain/domain.utils';
import type { FC } from 'react';

type PropTypes = {
  price: string;
  salePrice?: string;
  bestBottlePrice?: number;
  averageBottlePrice?: number;

  showBottlePrice?: boolean;
  showRealPrice?: boolean;
  showAverageBottlePrice?: boolean;

  storePageMode?: boolean;
  onSale: boolean;
  withSaleBadge?: boolean;
  withOldPrice?: boolean;
};

const ProductPrice: FC<PropTypes> = ({
  onSale,
  price,
  salePrice,
  bestBottlePrice,
  averageBottlePrice,
  showAverageBottlePrice,
  showBottlePrice,
  showRealPrice,
  storePageMode = false,
  withSaleBadge = true,
  withOldPrice = true,
}) => {
  const priceData = (price: string, onSale: boolean) => {
    if (showRealPrice && showAverageBottlePrice) {
      return (
        <>
          <span data-testid="current-product-price">${convertPrice(price).toFixed(2)}</span>
          <span style={{ fontSize: '13px', paddingLeft: '8px' }}>
            ${averageBottlePrice && averageBottlePrice.toFixed(2)} per bottle
          </span>
        </>
      );
    } else
      return showBottlePrice && bestBottlePrice ? (
        <>
          {onSale ? (
            <span>
              As Low As <s>{price}</s> ${bestBottlePrice.toFixed(2)} per bottle
            </span>
          ) : (
            <span>As Low As ${bestBottlePrice.toFixed(2)} per bottle</span>
          )}
        </>
      ) : (
        <span data-testid="current-product-price">${convertPrice(price).toFixed(2)}</span>
      );
  };

  return (
    <h3 className="product-price">
      {onSale && salePrice ? (
        <>
          {storePageMode === false && withOldPrice === true && (
            <span className="old-price" data-testid="old-product-price">
              ${convertPrice(price)}
            </span>
          )}

          {storePageMode === false && withSaleBadge === true && (
            <span className="product-badge">On sale</span>
          )}

          {priceData(salePrice, onSale)}
        </>
      ) : (
        <>{priceData(price, onSale)}</>
      )}
    </h3>
  );
};

export default ProductPrice;
