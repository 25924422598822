'use-client';

import Link from 'next/link';

interface LinkProps {
  className?: string;
  category: { path: string };
  isMobile: boolean;
  entityName?: string;
  text?: string;
  withLink?: boolean;
}

const SmartLink: React.FC<LinkProps> = ({
  className = 'title-link',
  category,
  isMobile,
  entityName = 'Products',
  text,
  withLink = true,
}) => {
  if (category.path.includes('undefined') || withLink === false) {
    return (
      <p className={className}> {text ? text : isMobile ? 'View All' : `View All ${entityName}`}</p>
    );
  }

  return (
    <Link className={className} href={category.path} prefetch={false}>
      {text ? text : isMobile ? 'View All' : `View All ${entityName}`}
    </Link>
  );
};

export default SmartLink;
