'use client';

import type { FC, ReactNode } from 'react';

type Props = {
  icon: ReactNode;
  className?: string;
  enabled: boolean;
  clickable?: boolean;
  onClick?: () => void;
};

const Icon: FC<Props> = ({ icon, enabled, className, clickable, onClick }) => {
  return (
    <>
      {clickable ? (
        <button className={className} onClick={onClick} disabled={!enabled}>
          {icon}
        </button>
      ) : (
        { icon }
      )}
    </>
  );
};

export default Icon;
