'use client';

import type { FC, ReactNode } from 'react';
import { createContext, useContext } from 'react';

type NavContextValues = {
  navCollapsed: boolean;
  isNavItemActive: (navItemPath: string) => boolean;
  toggleNavCollapsed: (force?: boolean) => void;
};

export const NavContext = createContext({} as NavContextValues);

export const useNavContext = () => useContext(NavContext);

type PropTypes = {
  children: ReactNode;
  values: NavContextValues;
};

export const NavProvider: FC<PropTypes> = ({ children, values }) => {
  return <NavContext.Provider value={values}>{children}</NavContext.Provider>;
};

export default NavProvider;
