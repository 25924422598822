import PropTypes from 'prop-types';

import SiteSearchForm from '../site-search-form/SiteSearchForm';
import { FC } from 'react';

type PropTypes = {
  toggleSearch: any;
  onSubmit: any;
};

const SearchFormWrapper: FC<PropTypes> = ({ toggleSearch, onSubmit }) => {
  return (
    <div className="dropdown-menu search-dropdown">
      <SiteSearchForm layout="line" onSubmit={onSubmit}>
        <button className="close" aria-label="Close" onClick={toggleSearch}>
          <span aria-hidden="true"></span>
        </button>
      </SiteSearchForm>
    </div>
  );
};

export default SearchFormWrapper;
