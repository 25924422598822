'use client';

import type { AnchorHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';
import { useMemo } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import { useNavContext } from '../context';

type PropTypes = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  children: ReactNode;
  usePlainLinks?: boolean;
};

const FooterNavItem: FC<PropTypes> = ({
  href = '',
  className,
  children,
  usePlainLinks = false,
  ...rest
}) => {
  const { toggleNavCollapsed, isNavItemActive } = useNavContext();

  const active = useMemo(() => {
    return isNavItemActive(href);
  }, [href, isNavItemActive]);

  return (
    <div onClick={() => toggleNavCollapsed(false)}>
      {usePlainLinks ? (
        <a href={href}>
          {' '}
          <span {...rest} className={clsx('nav-link', className, { active })}>
            {children}
          </span>
        </a>
      ) : (
        <Link href={href} prefetch={false}>
          <span {...rest} className={clsx('nav-link', className, { active })}>
            {children}
          </span>
        </Link>
      )}
    </div>
  );
};

export default FooterNavItem;
